<template>
	<div id="ag-grid-demo">
		<vx-card>
			<!-- TABLE ACTION ROW -->
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowModelType="rowModelType"
				serverSideStoreType="partial"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:cacheBlockSize="500"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@grid-ready="onGridReady"
				@column-resized="onColumnResized"
				@row-selected="onRowSelected"
				@model-updated="modelUpdated">
			</ag-grid-vue>
			<div class="vx-row">
					<div v-if="user_role == 'admin'" class="vx-col w-full md:w-1/2 flex justify-center md:justify-start">
						<vs-button color="dark" class="md:block" @click="gridSelectAll()">Select All</vs-button>
						<template v-if="selectedDomains.length">
							<vs-button class="md:block ml-4" @click="unsuspend()">Unsuspend</vs-button>
							<vs-button class="hidden md:block md:ml-4" color="dark" type="border" @click="showCart = showCart ?  false : true">{{ selectedDomains.length }} Selected</vs-button>
						</template>
					</div>
					<div class="vx-col w-full" :class="{ 'md:w-1/2': user_role == 'admin' }">
						<vs-pagination
						:total="totalPages"
						:max="maxPageNumbers"
						v-model="currentPage" />
					</div>
			</div>
		</vx-card>

		<takedown-report-cart v-if="showCart && selectedDomains.length" :cart-data="selectedDomains" @rowUnselected="rowUnselected" @cartUnsuspend="unsuspend()"/>
		
		<!-- For Domain Preview Component -->
		<router-view></router-view>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";
	import TakedownReportCart from '@/components/selected-domains-cart/TakedownReportCart.vue'

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
			TakedownReportCart,
		},
		data() {
			return {
				gridOptions: {},
				gridCount: 0,
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep'
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				rowModelType: "serverSide",
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							let route = {
								name: "suspension-list-domain-preview",
								params: { id: data.node.data.domain_id }
							};

							let link = document.createElement("a");
							link.href = this.$router.resolve(route).href;
							link.innerText = data.value;
							link.addEventListener("click", e => {
								e.preventDefault();
								this.$router.push(route);
							});

							return link;
						}
						else {
							return ''
						}
					},
					checkboxSelection: JSON.parse(localStorage.getItem('userDetails')).role == 'admin',
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['site','website','store','online','tech','press','host','fun','space','uno','pw','in.net'],
						suppressMiniFilter: true,
					},
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Transaction Date',
					field: 'creation_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: 'Status',
					field: 'status',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['A','SH','SHP','SHF','PD','D'],
						suppressMiniFilter: true,
					},
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'A':
									return `<div class="badge badge--success inline">${data.value}</div>`
								case 'SH':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'SHP':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'SHF':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'PD':
									return `<div class="badge badge--info inline">${data.value}</div>`
								case 'D':
									return `<div class="badge badge--dark inline">${data.value}</div>`
								default:
									return `<div>-</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 100,
				},
				{
					headerName: 'Action Date',
					field: 'action_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Reason',
					field: 'reason',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Row ID',
					field: 'id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				],
				selectedDomains: [],
				showCart: false,
				selectAll: false,
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			totalPages() {
				if(this.gridApi) {
					this.gridCount = this.gridApi.getDisplayedRowCount() - 1
				}
				else {
					this.gridCount = 0
				}
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			currentTimestamp: function(){
				return this.$store.getters.getCurrentTimestamp;
			},
			formattedTimestamp: function(){
				return this.currentTimestamp != null ? new Date(this.currentTimestamp) : this.currentTimestamp;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onGridReady(params) {
				let self = this;

				const endpoint = `${this.$SERVERSIDE}/node-server-hold/`;

				// Set Data Source
				params.api.setServerSideDatasource({
					getRows(params) {
						fetch(endpoint, {
							method: 'post',
							body: JSON.stringify(params.request),
							headers: {"Content-Type": "application/json; charset=utf-8"}
						})
						.then(httpResponse => httpResponse.json())
						.then(response => {
							if(response.lastRow) {
								// clear all overlays
								self.gridApi.hideOverlay();
								params.successCallback(response.rows, response.lastRow);
							}
							else {
								// clear all overlays
								self.gridApi.hideOverlay();
								// show 'no rows' overlay
								self.gridApi.showNoRowsOverlay();
								params.successCallback([], 0);
							}
							// Set displayed row count
							self.$store.dispatch("setListingFilteredCount", self.gridApi.getDisplayedRowCount());
						})
						.catch(error => {
							console.error(error);
							params.failCallback();
						})
					}
				});
			},
			modelUpdated() {
				// Iterate each node
				this.gridApi.forEachNode((node) => {
					if(node['data'] && this.selectedDomains.length) {
						let domainIDs = this.selectedDomains.map(x => x['id']);
						// Check if node is in selectedDomains
						if(domainIDs.includes(node['data']['id'])) {
							node.setSelected(true);
						}
						else {
							node.setSelected(false);
						}
					}
				});
				// Reset selectAll flag
				this.selectAll = false;
			},
			onRowSelected(event) {
				if(event.node.selected) {
					// Push to selectedDomains
					this.pushToArray(this.selectedDomains, event.node.data, 'id');
				}
				else {
					// Remove from selectedDomains
					this.removeFromArray(this.selectedDomains, event.node.data, 'id');
				}
			},
			rowUnselected(data) {
				this.gridApi.forEachNode((node) => {
					// Unselect the node with passed id
					if (node['data']['id'] == data.id) {
						node.setSelected(false);
					}
				});
				// Remove from selectedDomains
				this.removeFromArray(this.selectedDomains, data, 'id');
			},
			unsuspend() {
				// Save takedown domains
				this.$store.dispatch("setUnsuspensionDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/unsuspension' })
			},
			gridSelectAll() {
				this.gridApi.forEachNode((node) => {
					if(this.selectAll) {
						// Unselect the node
						node.setSelected(false);
						// Remove from selectedDomains
						this.removeFromArray(this.selectedDomains, node.data, 'domain_id');
					}
					else {
						// Select the node
						node.setSelected(true);
					}
				});
				// Toggle selectAll flag
				this.selectAll = !this.selectAll;
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "View Domain Details",
							action: function() {
								let routeData = self.$router.resolve({name: 'search', query: {domain_name: params.value}});
								window.open(routeData.href, '_blank');
							},
						},
						'separator',
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selected domains
				this.selectedDomains = [];
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel([
					{
					colId: "action_date",
					sort: "desc"
					}
				]);
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});
			
			// Get Listing Count
			this.$axiosSecure.get('/suspension-list-count')
			.then((response) => {
				// Set Listing Count
				this.$store.dispatch("setListingTotalCount", response.data);
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
				
				// Clear Listing Count
				this.$store.dispatch("clearListingCount");
			});

		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");

			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
		}
	}

</script>